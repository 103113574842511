import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

enum ValidationStatus {
  noAddressToValidate,
  notValidated,
  validating,
  validated,
  invalid,
  failed
}

enum Status {
  notSaved,
  saving,
  saved,
  failed
}

@Component({
  selector: 'app-onboarding-form',
  templateUrl: './onboarding-form.component.html',
  styleUrls: ['./onboarding-form.component.scss']
})
export class OnboardingFormComponent implements OnInit {
  timerID: any;
  options: any = [];
  showContact2 = false;
  showContact3 = false;
  siteKey: string;
  Status = Status;
  ValidationStatus = ValidationStatus;
  informationSaveStatus = Status.notSaved;
  addressValidationStatus = ValidationStatus.noAddressToValidate;
  validatedAddress: any;
  location = {
    'longitude': -95.71290,
    'latitude': 37.09020,
  };
  addressInfo: any = {
    'primary_address': '',
    'secondary_address': '',
    'city': '',
    'state': '',
    'zip': '',
  };

  address = new FormControl(
    '', [Validators.required, Validators.maxLength(250)]);
  address2 = new FormControl(
    '', [Validators.maxLength(250)]);
  city = new FormControl(
    '', [Validators.required, Validators.maxLength(250)]);
  state = new FormControl(
    '', [Validators.required, Validators.maxLength(2)]);
  zip = new FormControl(
    '', [Validators.required, Validators.maxLength(10)]);
  name = new FormControl(
    '', [Validators.required, Validators.maxLength(200)]);
  email = new FormControl(
    '', [Validators.required, Validators.email, Validators.maxLength(200)]);
  phone = new FormControl(
    '', [Validators.required, Validators.pattern("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$$"),
     Validators.maxLength(14)]);
  name2 = new FormControl(
    '', [Validators.maxLength(200)]);
  email2 = new FormControl(
    '', [Validators.email, Validators.maxLength(200)]);
  phone2 = new FormControl(
    '', [Validators.pattern("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$$"),
     Validators.maxLength(14)]);
  name3 = new FormControl(
    '', [Validators.maxLength(200)]);
  email3 = new FormControl(
    '', [Validators.email, Validators.maxLength(200)]);
  phone3 = new FormControl(
    '', [Validators.pattern("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$$"),
     Validators.maxLength(14)]);
  recaptcha = new FormControl(
    '', [Validators.required]);

  onboardingForm = this.fb.group({
    address: this.address,
    address2: this.address2,
    city: this.city,
    state: this.state,
    zip: this.zip,
    name: this.name,
    email: this.email,
    phone: this.phone,
    name2: this.name2,
    email2: this.email2,
    phone2: this.phone2,
    name3: this.name3,
    email3: this.email3,
    phone3: this.phone3,
    recaptcha: this.recaptcha,
  })

  addContact2() {
    this.showContact2 = true;
    (this.onboardingForm.get('name2') as FormGroup).setValidators(
      [Validators.required, Validators.maxLength(200)]);
    (this.onboardingForm.get('name2') as FormGroup).updateValueAndValidity();
    (this.onboardingForm.get('email2') as FormGroup).setValidators(
      [Validators.required, Validators.email, Validators.maxLength(200)]);
    (this.onboardingForm.get('email2') as FormGroup).updateValueAndValidity();
    (this.onboardingForm.get('phone2') as FormGroup).setValidators(
      [Validators.required, Validators.pattern(
          "^((\\+91-?)|0)?[0-9]{10}$"), Validators.maxLength(10)]);
    (this.onboardingForm.get('phone2') as FormGroup).updateValueAndValidity();
  }

  addContact3() {
    this.showContact3 = true;
    (this.onboardingForm.get('name3') as FormGroup).setValidators(
      [Validators.required, Validators.maxLength(200)]);
    (this.onboardingForm.get('name3') as FormGroup).updateValueAndValidity();
    (this.onboardingForm.get('email3') as FormGroup).setValidators(
      [Validators.required, Validators.email, Validators.maxLength(200)]);
    (this.onboardingForm.get('email3') as FormGroup).updateValueAndValidity();
    (this.onboardingForm.get('phone3') as FormGroup).setValidators(
      [Validators.required, Validators.pattern(
          "^((\\+91-?)|0)?[0-9]{10}$"), Validators.maxLength(10)]);
    (this.onboardingForm.get('phone3') as FormGroup).updateValueAndValidity();
  }

  removeContact2() {
    this.showContact2 = false;
    (this.onboardingForm.get('name2') as FormGroup).setValidators([]);
    (this.onboardingForm.get('name2') as FormGroup).updateValueAndValidity();
    (this.onboardingForm.get('email2') as FormGroup).setValidators([]);
    (this.onboardingForm.get('email2') as FormGroup).updateValueAndValidity();
    (this.onboardingForm.get('phone2') as FormGroup).setValidators([]);
    (this.onboardingForm.get('phone2') as FormGroup).updateValueAndValidity();
  }

  removeContact3() {
    this.showContact3 = false;
    (this.onboardingForm.get('name3') as FormGroup).setValidators([]);
    (this.onboardingForm.get('name3') as FormGroup).updateValueAndValidity();
    (this.onboardingForm.get('email3') as FormGroup).setValidators([]);
    (this.onboardingForm.get('email3') as FormGroup).updateValueAndValidity();
    (this.onboardingForm.get('phone3') as FormGroup).setValidators([]);
    (this.onboardingForm.get('phone3') as FormGroup).updateValueAndValidity();
  }

  getTypeahead = (data: any) => {
    this.http.post(
      'https://us-central1-livingprint-onboarding.cloudfunctions.net/getTypeaheadValues', data)
      .subscribe((result: any) => {
        // console.log('response: ', result);
        if (result.data.success) {
          // console.log(result.data.data);
          this.options = result.data.data;
        } else {
          // Return no results
        }
      }, (err) => {
          console.log(err);
      })
  }

  startTypeaheadTimer = () => {
    clearTimeout(this.timerID);
    // console.log('cleared timer');
    this.timerID = setTimeout(() => {
      // console.log('timer ended')
      this.getTypeahead({
        address: this.onboardingForm.value.address,
        latitude: this.location.latitude,
        longitude: this.location.longitude
      });
    }, 300);
  }

  autoFillAddressWithTypeaheadSelection = (option: any) => {
    (this.onboardingForm.get('address') as FormControl)
    .setValue(option.address_main);
    (this.onboardingForm.get('city') as FormControl)
    .setValue(option.city);
    (this.onboardingForm.get('state') as FormControl)
    .setValue(option.state);
    this.validateAddress();
  }

  validateAddress = () => {
    this.addressValidationStatus = ValidationStatus.validating;
    this.addressInfo.primary_address = (this.onboardingForm.get('address') as FormControl).value
    this.addressInfo.secondary_address = (this.onboardingForm.get('address2') as FormControl).value
    this.addressInfo.city = (this.onboardingForm.get('city') as FormControl).value
    this.addressInfo.state = (this.onboardingForm.get('state') as FormControl).value
    this.addressInfo.zip = (this.onboardingForm.get('zip') as FormControl).value
    this.http.post(
      'https://us-central1-livingprint-onboarding.cloudfunctions.net/validateAddress', this.addressInfo)
      .subscribe((result: any) => {
        // console.log('response: ', result);
        this.validatedAddress = {
          address: result.data.Addr_Result[0].dadl1,
          address2: result.data.Addr_Result[0].dadl3,
          city: result.data.Addr_Result[0].dctys,
          state: result.data.Addr_Result[0].dstaa,
          zip: result.data.Addr_Result[0].zipc + '-' + result.data.Addr_Result[0].addon,
          dpva: result.data.Addr_Result[0].DPVA
        };
        if (result.data.success) {
          (this.onboardingForm.get('address') as FormControl)
          .setValue(this.validatedAddress.address);
          (this.onboardingForm.get('address2') as FormControl)
          .setValue(this.validatedAddress.address2);
          (this.onboardingForm.get('city') as FormControl)
          .setValue(this.validatedAddress.city);
          (this.onboardingForm.get('state') as FormControl)
          .setValue(this.validatedAddress.state);
          (this.onboardingForm.get('zip') as FormControl)
          .setValue(this.validatedAddress.zip);
          if (result.data.Addr_Result[0].DPVA === 'Y' || this.validatedAddress.dpva === 'S' || this.validatedAddress.dpva === 'D') {
            this.addressValidationStatus = ValidationStatus.validated;
          }
          else {
            this.addressValidationStatus = ValidationStatus.invalid;
          }
        } else {
          this.addressValidationStatus = ValidationStatus.failed;
          console.log('Error validating address, please try again later.')
        }
      }, (err) => {
          this.addressValidationStatus = ValidationStatus.failed;
          console.log(err);
      })
  };

  resetValidationStatus = () => {
    if (
      (this.onboardingForm.get('address') as FormControl).valid &&
      (this.onboardingForm.get('address2') as FormControl).valid &&
      (this.onboardingForm.get('city') as FormControl).valid &&
      (this.onboardingForm.get('state') as FormControl).valid &&
      (this.onboardingForm.get('zip') as FormControl).valid
    ) {
      if (this.validatedAddress !== undefined) {
        if (
          (this.onboardingForm.get('address') as FormControl).value === this.validatedAddress.address &&
          (this.onboardingForm.get('address2') as FormControl).value === this.validatedAddress.address2 &&
          (this.onboardingForm.get('city') as FormControl).value === this.validatedAddress.city &&
          (this.onboardingForm.get('state') as FormControl).value === this.validatedAddress.state &&
          (this.onboardingForm.get('zip') as FormControl).value === this.validatedAddress.zip
        ) {
          if (this.validatedAddress.dpva === 'Y' || this.validatedAddress.dpva === 'S' || this.validatedAddress.dpva === 'D') {
            this.addressValidationStatus = ValidationStatus.validated;
          } else {
            this.addressValidationStatus = ValidationStatus.invalid;
          }
        } else {
          this.addressValidationStatus = ValidationStatus.notValidated;
        }
      } else {
        this.addressValidationStatus = ValidationStatus.notValidated;
      }
    } else {
      this.addressValidationStatus = ValidationStatus.noAddressToValidate;
    }
  };

  onKeyup = (event: any) => {
    // console.log(event);
    if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'Tab' && event.key !== 'Enter') {
      this.startTypeaheadTimer();
    } else if (event.key === 'Enter' || event.key === 'Tab') {
      
    }
  };

  getAddressErrorMessage() {
    if (this.address.hasError('required')) {
      return 'Address is required';
    }

    return this.address.hasError('maxlength') ?
     'Address cannot exceed 250 characters in length' : '';
  }
  getAddress2ErrorMessage() {
    return this.address2.hasError('maxlength') ?
     'Address 2 cannot exceed 250 characters in length' : '';
  }
  getCityErrorMessage() {
    if (this.city.hasError('required')) {
      return 'City is required';
    }

    return this.city.hasError('maxlength') ?
     'City cannot exceed 250 characters in length' : '';
  }
  getStateErrorMessage() {
    if (this.state.hasError('required')) {
      return 'State is required';
    }

    return this.state.hasError('maxlength') ?
     'State cannot exceed 2 characters in length, '+
     'please use the state abbreviation. Example: "Texas" is "TX".' : '';
  }
  getZipErrorMessage() {
    if (this.zip.hasError('required')) {
      return 'ZIP Code is required';
    }

    return this.zip.hasError('maxlength') ?
     'ZIP Code cannot exceed 9 characters in length' : '';
  }
  getNameErrorMessage() {
    if (this.name.hasError('required')) {
      return 'Name is required';
    }

    return this.name.hasError('maxlength') ?
     'Name cannot exceed 200 characters in length' : '';
  }
  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Email is required';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  getPhoneErrorMessage() {
    if (this.phone.hasError('required')) {
      return 'Phone Number is required';
    }

    return this.phone.hasError('pattern') ? 'Not a valid Phone Number' : '';
  }

  scrollToTop() {
    window.scrollTo(0,0);
  }

  onSubmit() {
    let contacts = [{}];

    if(!this.showContact2 && !this.showContact3) {
      contacts = [
        {
          name: this.onboardingForm.value.name,
          email: this.onboardingForm.value.email,
          phone: this.onboardingForm.value.phone
        }
      ]
    } else if(this.showContact2 && !this.showContact3) {
      contacts = [
        {
          name: this.onboardingForm.value.name,
          email: this.onboardingForm.value.email,
          phone: this.onboardingForm.value.phone
        },
        {
          name: this.onboardingForm.value.name2,
          email: this.onboardingForm.value.email2,
          phone: this.onboardingForm.value.phone2
        }
      ]
    } else if(this.showContact2 && this.showContact3) {
      contacts = [
        {
          name: this.onboardingForm.value.name,
          email: this.onboardingForm.value.email,
          phone: this.onboardingForm.value.phone
        },
        {
          name: this.onboardingForm.value.name2,
          email: this.onboardingForm.value.email2,
          phone: this.onboardingForm.value.phone2
        },
        {
          name: this.onboardingForm.value.name3,
          email: this.onboardingForm.value.email3,
          phone: this.onboardingForm.value.phone3
        }
      ]
    }

    interface onboardingFormData {
      address: string,
      address2: string,
      city: string,
      state: string,
      zip: string,
      contacts: Array<object>,
    }

    let onboardingFormData: onboardingFormData = {
      address: this.onboardingForm.value.address,
      address2: this.onboardingForm.value.address2,
      city: this.onboardingForm.value.city,
      state: this.onboardingForm.value.state,
      zip: this.onboardingForm.value.zip,
      contacts: contacts
    };

    // console.log('onboardingFormData: ', onboardingFormData);
    this.scrollToTop();
    this.informationSaveStatus = Status.saving;
    // console.log(onboardingFormData);
    this.http.post(
      'https://us-central1-livingprint-onboarding.cloudfunctions.net/'+
      'saveInformation', onboardingFormData)
      .subscribe((result: any) => {
        // console.log('response: ', result);
        if (result.message === 'Information saved successfully.') {
          // Show Rive Animation
          this.informationSaveStatus = Status.saved;
        } else {
          // Show Please Try Again Later Message
          this.informationSaveStatus = Status.failed;
        }
      }, (err) => {
          this.informationSaveStatus = Status.failed;
      })
  }

  constructor(private http: HttpClient, private fb: FormBuilder) {
    this.siteKey = '6LeuS8ccAAAAAMwdLGk7H77heICeN8UGr_mGMWLn';
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setTypeaheadLocation);
    } else {
      console.log('Geolocation is not supported by this browser.'+
        'Defaulting to the center of the US.');
    }
  }

  setTypeaheadLocation = (location: any) => {
    this.location = {
      'longitude': location.coords.longitude,
      'latitude': location.coords.latitude
    }
  }

  //:ADDRESS/:COUNTRIES/:LATITUDE/:LONGITUDE

  ngOnInit() {
    this.getLocation();
  }

  onSelectionChanged = (event: MatAutocompleteSelectedEvent) => {
    // console.log(event.option.value);
    this.autoFillAddressWithTypeaheadSelection(event.option.value);
  }

}
