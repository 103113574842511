<div id="pageWrapper">
  <div id="contentWrapper">
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <div class="row padding-top">
      <p class="supportText">Have Questions?&nbsp;</p>
      <a class="supportText supportLink" target="_blank" href="https://livingprint.com/contact-us">Contact us</a>
      <p class="supportText">.</p>
    </div>
    <p class="copyright">© Copyright 2021-2023, AccuZIP Inc.</p>
  </div>
</div>