<div *ngIf="informationSaveStatus === Status.notSaved || informationSaveStatus === Status.failed" class="pageWrapper">
  <img src="../../assets/logo.png">
  <h1>LIVINGPRINT Onboarding Form</h1>
  <p>Please fill out all fields marked with a '*' as they are required. You can add up to 3 contacts.</p>
  <div>
    <form (ngSubmit)="onSubmit()" [formGroup]="onboardingForm" autocomplete="off">
      <mat-form-field appearance="standard">
        <mat-label>Address</mat-label>
        <input appTab autocomplete="no" type="text" name="address" matInput [formControl]="address"
          [matAutocomplete]="auto"
          (keyup)="onKeyup($event)"
          (input)="resetValidationStatus()"
          required>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">
          <mat-option *ngFor="let option of options" [value]="option">
            {{option.value}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="this.address.invalid">{{getAddressErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Address 2</mat-label>
        <input autocomplete="no" type="text" name="address2" matInput [formControl]="address2" (input)="resetValidationStatus()">
        <mat-error *ngIf="this.address2.invalid">{{getAddress2ErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>City</mat-label>
        <input autocomplete="no" type="text" name="city" matInput [formControl]="city" (input)="resetValidationStatus()" required>
        <mat-error *ngIf="this.city.invalid">{{getCityErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>State</mat-label>
        <input autocomplete="no" type="text" name="state" matInput [formControl]="state" (input)="resetValidationStatus()" required>
        <mat-error *ngIf="this.state.invalid">{{getStateErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>ZIP Code</mat-label>
        <input autocomplete="no" type="text" name="zip" matInput [formControl]="zip" [dropSpecialCharacters]="false" mask="00000-9999" (input)="resetValidationStatus()" required>
        <mat-error *ngIf="this.zip.invalid">{{getZipErrorMessage()}}</mat-error>
      </mat-form-field>
      <button
      *ngIf="addressValidationStatus === ValidationStatus.noAddressToValidate || addressValidationStatus === ValidationStatus.notValidated"
      class="validateButton" type="button" mat-flat-button color="primary"
      [disabled]="addressValidationStatus === ValidationStatus.noAddressToValidate"
      (click)="validateAddress()">
      Validate Address</button>
      <mat-progress-bar *ngIf="addressValidationStatus === ValidationStatus.validating" mode="indeterminate"></mat-progress-bar>
      <div id="validationSuccessValidResultWrapper" *ngIf="addressValidationStatus === ValidationStatus.validated">
        <mat-icon aria-label="Check Mark">check</mat-icon>
        <p>Address validated successfully!</p>
      </div>
      <div id="validationSuccessInvalidResultWrapper" *ngIf="addressValidationStatus === ValidationStatus.invalid">
        <mat-icon aria-label="Report Problem">report_problem</mat-icon>
        <p>Address could not be verified, please manually verify it and continue.</p>
      </div>
      <div id="contactInformationExplanerText">
        <p>For Contact 1 please enter the information you would like displayed in the app and provided to the customers. For example you could use your company name in the "Name" field and a general email and phone number for your business. If you would like other team members or other individuals to be notified when an order is placed please enter any other information in contacts 2 and 3.</p>
      </div>
      <div id="contact1" class="contactInfo">
        <h2>Contact 1</h2>
        <mat-form-field appearance="standard">
          <mat-label>Name</mat-label>
          <input type="text" name="name" matInput [formControl]="name" required>
          <mat-error *ngIf="this.name.invalid">{{getNameErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Email</mat-label>
          <input type="text" name="email" matInput [formControl]="email" required>
          <mat-error *ngIf="this.email.invalid">{{getEmailErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Phone Number</mat-label>
          <input type="text" name="phone" matInput [formControl]="phone" [dropSpecialCharacters]="false" mask="(000) 000-0000" required>
          <mat-error *ngIf="this.phone.invalid">{{getPhoneErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <button *ngIf="!showContact2" mat-button (click)="addContact2()">Add Second Contact</button>
      <div *ngIf="showContact2" id="contact2" class="contactInfo">
        <div class="contactLabelWrapper">
          <h2>Contact 2</h2>
          <button *ngIf="!showContact3" mat-icon-button color="warn" aria-label="Trash Icon" (click)="removeContact2()">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
        <mat-form-field appearance="standard">
          <mat-label>Name</mat-label>
          <input type="text" name="name2" matInput [formControl]="name2" required>
          <mat-error *ngIf="this.name2.invalid">{{getNameErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Email</mat-label>
          <input type="text" name="email2" matInput [formControl]="email2" required>
          <mat-error *ngIf="this.email2.invalid">{{getEmailErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Phone Number</mat-label>
          <input type="text" name="phone2" matInput [formControl]="phone2" [dropSpecialCharacters]="false" mask="(000) 000-0000" required>
          <mat-error *ngIf="this.phone2.invalid">{{getPhoneErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <button *ngIf="showContact2 && !showContact3" mat-button (click)="addContact3()">Add Third Contact</button>
      <div *ngIf="showContact2 && showContact3" id="contact3" class="contactInfo">
        <div class="contactLabelWrapper">
          <h2>Contact 3</h2>
          <button mat-icon-button color="warn" aria-label="Trash Icon" (click)="removeContact3()">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
        <mat-form-field appearance="standard">
          <mat-label>Name</mat-label>
          <input type="text" name="name3" matInput [formControl]="name3" required>
          <mat-error *ngIf="this.name3.invalid">{{getNameErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Email</mat-label>
          <input type="text" name="email3" matInput [formControl]="email3" required>
          <mat-error *ngIf="this.email3.invalid">{{getEmailErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Phone Number</mat-label>
          <input type="text" name="phone3" matInput [formControl]="phone3" [dropSpecialCharacters]="false" mask="(000) 000-0000" required>
          <mat-error *ngIf="this.phone3.invalid">{{getPhoneErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <div class="recaptchaWrapper" [ngClass.lt-sm]="['recaptchaWrapperSmall']">
        <ngx-recaptcha2 [siteKey]="siteKey" [formControl]="recaptcha"></ngx-recaptcha2>
      </div>
      <div class="submitButtonWrapper">
        <button type="submit" mat-flat-button color="primary" [disabled]="!onboardingForm.valid || addressValidationStatus !== ValidationStatus.validated && addressValidationStatus !== ValidationStatus.invalid">Submit</button>
      </div>
    </form>
  </div>
</div>

<div id="spinnerWrapper" *ngIf="informationSaveStatus === Status.saving">
  <mat-spinner></mat-spinner>
</div>

<div class="successPageWrapper" *ngIf="informationSaveStatus === Status.saved">
  <p class="titleText">Information Saved Successfully!</p>
  <div id="riveSuccessAnimationWrapper">
    <canvas riv="check_success_animation" width="300" height="300">
      <riv-animation index=0 play></riv-animation>
    </canvas>
  </div>
  <p class="subtitleText">Next Steps...</p>
  <mat-vertical-stepper #stepper>
    <mat-step label="Create Marketing Material">
      <div class="textSpacer"></div>
      <div class="textSpacer"></div>
      <p class="bodyText aboutStepperText">Let your customers know that you now
         offer exclusive LIVINGMAIL postcards!
        <br>
        <br>
        AccuZIP has prepared the marketing material for you. Feel free to brand
         it and make changes where you see fit. The marketing material will be
         provided to you via email. It will be sent to the email address listed
         under "Contact 1" in the form you filled out.
      </p>
      <div>
        <div class="stepperButtonWrapper">
          <button mat-flat-button color="accent" matStepperNext>Next</button>
        </div>
      </div>
    </mat-step>
    <mat-step label="Receive Orders">
      <div class="textSpacer"></div>
      <div class="textSpacer"></div>
      <p class="bodyText aboutStepperText">Your customers will follow the steps
         on the marketing material to create and submit an amazing LIVINGMAIL
          postcard. You will receive an email with a download link to the
           LIVINGMAIL camera-ready postcard artwork</p>
      <div class="stepperButtonWrapper">
        <button mat-flat-button color="accent" matStepperPrevious>Back</button>
        <div class="spacer"></div>
        <button mat-flat-button color="accent" matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step label="Prepare and Send Orders">
      <div class="textSpacer"></div>
      <div class="textSpacer"></div>
      <p class="bodyText aboutStepperText">Prepare the mailing and merge the
         name, address and IM™  barcode with the LIVINGMAIL postcard.
         <br>
         <br>
         When the mail recipients receive the LIVINGMAIL postcard, they will use
          the camera on their iOS or Android device to scan the postcard and
           watch the LIVINGMAIL postcard come to life on their device with
            music, custom backgrounds, photos and videos your customer created
             in minutes.
        </p>
      <div class="stepperButtonWrapper">
        <button mat-flat-button color="accent" matStepperPrevious>Back</button>
        <div class="spacer"></div>
        <button mat-flat-button color="accent" (click)="stepper.reset()">Reset</button>
      </div>
    </mat-step>

    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
  </mat-vertical-stepper>
  <a target="_blank" href="https://livingprint.com/bulk">
    <button mat-flat-button color="primary" >Done</button>
  </a>
</div>